import {CToast, CToastBody, CToastClose, CToastHeader} from '@coreui/react-pro'
import {Actions} from '../redux/actions/action'
import MasterDataService from '../services/MasterDataService'
import JsBarcode from 'jsbarcode'
import jsPDF from 'jspdf'
import React from 'react'

export function formatDatetime(datetime?: string | null): string {
  if (datetime) {
    const d = new Date(datetime)
    const date = d.toISOString().split('T')[0]
    const time = d.toTimeString().split(' ')[0]
    return `${date} ${time}`
  }
  return ''
}

export function convertDataToOptions(data: any) {
  const result = [{value: '', label: ''}]
  if (data.length > 0) {
    data.map((record: any) =>
      result.push({value: record.code, label: record.code})
    )
  }
  return result
}

export function convertDataToOptionsWithName(data: any) {
  const result = [{value: '', label: ''}]
  if (data.length > 0) {
    data.map((record: any) =>
      result.push({value: record.code, label: record.name})
    )
  }
  return result
}

export function convertDataToOptionsMultiSelectFilters(data: any) {
  const result: any = []
  if (data.length > 0) {
    data.map((record: any) =>
      result.push({
        value: record.code,
        label: record.name,
        text: record.name,
        selected: false
      })
    )
  }
  return result
}

export const mapFilters = (filters: any) =>
  filters.map((selectedFilters: any) => selectedFilters.value)

export function getToastForToaster(msg: any, color: any, autoHide: boolean) {
  return (
    <CToast
      autohide={autoHide}
      color={color}
      className={'text-white align-items-center'}
    >
      <div className='d-flex'>
        <CToastBody>{msg}</CToastBody>
        <CToastClose className='me-2 m-auto' white />
      </div>
    </CToast>
  )
}

export function getErrorToast(error: any) {
  return (
    <CToast
      autohide={false}
      color='danger'
      className={'text-white align-items-center'}
    >
      <CToastHeader closeButton>
        <svg
          className='rounded me-2'
          width='20'
          height='20'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='xMidYMid slice'
          focusable='false'
          role='img'
        >
          <rect width='100%' height='100%' fill='red'></rect>
        </svg>
        <strong className='me-auto'>Response status {error.status}</strong>
      </CToastHeader>
      <CToastBody>
        <span>{error.message}</span>
      </CToastBody>
    </CToast>
  )
}

export const TRANSPORT_MODE = {
  AIR: {name: 'AIR', fileType: 'MAWB'},
  ROAD: {name: 'ROAD', fileType: 'CMR'}
}

export const itemsPerPageOptions = [
  {value: '5', label: '5'},
  {value: '10', label: '10'},
  {value: '15', label: '15'},
  {value: '20', label: '20'},
  {value: '50', label: '50'}
]

export function getDefaultItemsPerPage(): number {
  const rowHeight = 41 // Height of a row
  const maxRows = Math.floor((window.innerHeight - 450) / rowHeight) // Maximum amount of rows which fits in the screen
  const maxItemsPerPage = (
    maxRows > 0 ? maxRows : itemsPerPageOptions[0].value
  ) as number
  // Return the closest option available based on the itemsPerPageOptions array and the calculated maxItemsPerPage
  return +itemsPerPageOptions.reduce((prev, curr) => {
    return Math.abs(+curr.value - maxItemsPerPage) <
      Math.abs(+prev.value - maxItemsPerPage)
      ? curr
      : prev
  }, itemsPerPageOptions[0]).value
}

export function storeMasterDataInRedux(dispatch: any) {
  const mds = new MasterDataService()
  Promise.all([
    mds.getAllImportLocations(),
    mds.getAllExportLocations(),
    mds.getAllImporters(),
    mds.getAllWarehouses(),
    mds.getAllAirports(),
    mds.getAllExporters(),
    mds.getPossibleLaneCombinations(),
    mds.getLoadingUnitDestinationLmcCombination(),
    mds.getLoadingUnitDestinations(),
    mds.getLoadingUnitLmcs(),
    mds.getAllCities()
  ])
    .then(
      ([
        importLocations,
        exportLocations,
        importers,
        warehouses,
        airports,
        exporters,
        possibleLaneCombinations,
        loadingUnitDestinationLmcCombination,
        loadingUnitDestinations,
        loadingUnitLmcs,
        cities
      ]) => {
        dispatch({type: Actions.MASTER_DATA.SET_AIRPORTS, payload: airports})
        dispatch({
          type: Actions.MASTER_DATA.SET_IMPORT_LOCATIONS,
          payload: importLocations
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_EXPORT_LOCATIONS,
          payload: exportLocations
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_WAREHOUSES,
          payload: warehouses
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_IMPORTERS,
          payload: importers
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_EXPORTERS,
          payload: exporters
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_POSSIBLE_LANE_COMBINATIONS,
          payload: possibleLaneCombinations
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_LOADINGUNIT_DESTINATION_LMC_COMBINATION,
          payload: loadingUnitDestinationLmcCombination
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_LOADINGUNIT_DESTINATIONS,
          payload: loadingUnitDestinations
        })
        dispatch({
          type: Actions.MASTER_DATA.SET_LOADINGUNIT_LMCS,
          payload: loadingUnitLmcs
        })
        dispatch({type: Actions.MASTER_DATA.SET_CITIES, payload: cities})
      }
    )
    .catch((error) => console.error(error))
}

export const multiSelectStyle = {
  control: (style: any, state: any) => ({
    ...style,
    borderRadius: '0.375rem',
    borderColor: state.isFocused ? '#6557e4' : '#b1b7c1',
    hoverBorderColor: '#6557e4',
    boxShadow: state.isFocused
      ? '0 0 0 0.25rem rgb(50 31 219 / 25%)'
      : '0 !important',
    ':hover': {
      borderColor: state.isFocused ? '#6557e4' : '#b1b7c1'
    }
  })
}

// Function to generate the loading unit labels as PDF.
export function printBarcodeLabelsPDF(
  loadingUnitIds: string[],
  barcodeContainerId: any,
  pdfName: string,
  headerText = '',
  destination?: string,
  lmc?: string
) {
  const barcodeContainer = document.getElementById(barcodeContainerId)
  loadingUnitIds.forEach((luId: string) => {
    const canvas = document.createElement('canvas')
    canvas.setAttribute('barcodeText', luId)
    if (headerText) {
      canvas.setAttribute('headerText', headerText)
    }
    if (destination && lmc) {
      canvas.setAttribute('destination', destination)
      canvas.setAttribute('lmc', lmc)
    }
    JsBarcode(canvas, luId, {
      width: 5,
      height: 250,
      marginTop: 0,
      marginLeft: 0,
      displayValue: false
    })
    barcodeContainer!.appendChild(canvas)
  })
  // noinspection JSPotentiallyInvalidConstructorUsage
  const pdf = new jsPDF({orientation: 'landscape'})
  const barcodeImages = barcodeContainer!.querySelectorAll('canvas')
  barcodeImages.forEach((canvas, index) => {
    if (index > 0) {
      pdf.addPage()
    }
    const imgData = canvas.toDataURL()
    const pageWidth = pdf.internal.pageSize.getWidth()
    const pageHeight = pdf.internal.pageSize.getHeight()
    const imgWidth = 195
    const imgHeight = 85
    const xPosition = (pageWidth - imgWidth) / 2
    const yPosition = (pageHeight - imgHeight) / 2
    const aboveBarcodeText = canvas.getAttribute('headerText')
    const belowBarcodeText = canvas.getAttribute('barcodeText')
    const destination = canvas.getAttribute('destination')
    const lmc = canvas.getAttribute('lmc')
    const safeZone = 15
    pdf.addImage(
      imgData,
      'JPEG',
      xPosition,
      yPosition,
      imgWidth,
      imgHeight,
      'barcode',
      'FAST'
    )
    if (aboveBarcodeText) {
      pdf.setFontSize(40)
      pdf.text(aboveBarcodeText, pageWidth / 2, yPosition - safeZone, {
        align: 'center'
      })
    } else if (destination && lmc) {
      pdf.setFontSize(80)
      pdf.text(destination, pageWidth / 2, yPosition - 30, {align: 'center'})
      pdf.setFontSize(50)
      pdf.text('LMC: ' + lmc, pageWidth / 2, yPosition - 5, {
        align: 'center'
      })
    }
    if (belowBarcodeText) {
      pdf.setFontSize(30)
      pdf.text(
        belowBarcodeText,
        pageWidth / 2,
        yPosition + imgHeight + safeZone,
        {align: 'center'}
      )
    }
  })
  // remove the temporary barcode images
  barcodeContainer!.innerHTML = ''

  pdf.save(pdfName)
}

export function setSorts(columnSorter: any, defaultSortColumn: any) {
  const sorts = []
  if (columnSorter && columnSorter.state !== 0) {
    sorts.push({
      key: columnSorter.column,
      direction: columnSorter.state.toUpperCase()
    })
  } else {
    sorts.push({
      key: defaultSortColumn,
      direction: 'DESC'
    })
  }
  return sorts
}

// Regex for only allow alphabetic characters, digits, hyphen, period, and space
export const STRING_VALIDATION_REGEX = /^[a-zA-Z0-9äöüÄÖÜß_#. -]*$/

export const handleFilterStringInputChange = (event: any, setter: any) => {
  const value = event.target.value
  if (STRING_VALIDATION_REGEX.test(value) && setter) {
    setter(value)
  }
}
