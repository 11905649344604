import {Actions} from '../actions/action'
import {PayloadAction} from '@reduxjs/toolkit'

export interface SidebarState {
  show: boolean
  unfoldable: boolean
}

const initialState: SidebarState = {
  show: true,
  unfoldable: true
}

export default function sidebarReducer(
  state: SidebarState = initialState,
  action: PayloadAction<boolean>
): SidebarState {
  switch (action.type) {
    case Actions.SIDEBAR.UPDATE_VISIBILITY: {
      return {...state, show: action.payload}
    }
    case Actions.SIDEBAR.UPDATE_TOGGLE: {
      return {...state, unfoldable: action.payload}
    }
    default:
      return state
  }
}
