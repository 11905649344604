import {combineReducers} from 'redux'
import sidebarConfig from './sidebar-reducer'
import userDetails from './user-reducer'
import masterData from './masterdata-reducer'
import orderView from './order-view-reducer'
import timeReducer from './time-reducer'

const rootReducer = combineReducers({
  sidebarConfig,
  userDetails,
  masterData,
  orderView,
  timeReducer
})

export default rootReducer
