import React, {Component, lazy, Suspense} from 'react'
import {HashRouter, Route, Routes} from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

const Layout = lazy(() => import('./layout/Layout'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path='*' element={<Layout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
